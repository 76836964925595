import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import * as log from "loglevel";
import remote from "loglevel-plugin-remote";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import SimplePeer, { Instance, SignalData } from "simple-peer";
import "../css/conference.css";
import { DefaultLogger } from "../modules/logging/DefaultLogger";
import ErrorComponent from "./Error";
import { ApplicationState } from "../store";
import * as LoginStore from "../store/Login";
import { ConferenceParams } from "./ConferenceRoomRoute";
import LanguageContext from "./LanguageContext";
import Icon, { IconType } from "./FontSymbols";
import { Popup } from "./Popup";
import { Button } from "react-bootstrap";
import { VoiceAudioDetector } from "../modules/voiceDetection/VoiceAudioDetector";
import BaseReactComponent from "./BaseReactComponent";
import screenfull from "screenfull";

var DetectRTC = require("detectrtc");
var initLayoutContainer = require("opentok-layout-js");

interface PeerInfo {
    connectionId: string;
}

interface ICEServers {
    username: string;
    credential: string;
    urls: string[];
}

interface ConferenceRoomState {
    conferenceError?: string;
    localStreamError?: string;
    isCameraEnabled: boolean;
    isMicrophoneEnabled: boolean;
    isSingleUserEnabled: boolean;
    amountOfCameras: number;
    cameraFacingMode: "user" | "environment";
    localPeerId: string;
    videoTrackLabel: string;
    audioTrackLabel: string;
    currentPeerId: string; // Indicates which peer to stream for Picture in Picture.
    isLocalUserTalking: boolean;
    isFullscreen: boolean;

    // Used in case the user doesn't have local media devices/ refuses to give access
    noMediaDetected: boolean;   // If unable to get local media (either device doesn't exist or not detected), set to true
    showNoMediaPopup: boolean;  // If unable to get local media, trigger a popup that will ask the user if it's ok to continue to with not local media.
    showErrorMessage: boolean; // If user refuses to continue with no local media, this will show an message to the user.
}

type ConferenceRoomProps = {
    login: LoginStore.LoginState;
}
    & typeof LoginStore.actionCreators
    & RouteComponentProps<ConferenceParams>;

class ConferenceRoom extends BaseReactComponent<ConferenceRoomProps, ConferenceRoomState> {
    static contextType = LanguageContext;

    private hubConnection?: HubConnection;
    private localStream: Promise<MediaStream | null>;
    private localVideo: HTMLVideoElement | null = null;
    private peers: { [peerId: string]: Instance | null | undefined };
    private streams: { [peerId: string]: MediaStream[] | undefined };
    private videos: { [peerId: string]: HTMLVideoElement | undefined };
    private currentSpeakerVideo: HTMLVideoElement | undefined;

    private voiceDetector: { [peerId: string]: VoiceAudioDetector };
    private localVoiceDetector: VoiceAudioDetector | undefined;

    private lastIceCredentialsFetch = 0;
    private getIceServersPromise?: Promise<ICEServers[] | null>;

    public audioContext: AudioContext | null = null;

    constructor(props: ConferenceRoomProps) {
        super(props);
        this.initLocalStream = this.initLocalStream.bind(this);
        this.tryStartConferenceAsync = this.tryStartConferenceAsync.bind(this);
        this.handleCameraButtonClick = this.handleCameraButtonClick.bind(this);
        this.handleMicrophoneButtonClick = this.handleMicrophoneButtonClick.bind(this);
        this.handleHangupButtonClickAsync = this.handleHangupButtonClickAsync.bind(this);
        this.handleFlipButtonClick = this.handleFlipButtonClick.bind(this);
        this.handleMultipleUserToggle = this.handleMultipleUserToggle.bind(this);
        this.layoutVideos = this.layoutVideos.bind(this);
        this.handleFullscreenButtonClick = this.handleFullscreenButtonClick.bind(this);

        this.state = {
            isCameraEnabled: true,
            isMicrophoneEnabled: true,
            amountOfCameras: 0,
            cameraFacingMode: "user",
            localPeerId: "",
            videoTrackLabel: "",
            audioTrackLabel: "",
            currentPeerId: "",
            isLocalUserTalking: false,
            isSingleUserEnabled: false,
            noMediaDetected: false,
            showNoMediaPopup: false,
            showErrorMessage: false,
            isFullscreen: false
        };

        this.peers = {};
        this.streams = {};
        this.voiceDetector = {};
        this.videos = {}

        this.localStream = this.initLocalStream();

        log.setLevel(log.levels.DEBUG, true);
        this.configureRemoteLogging(this.props.match.params.roomId);
        this.configureAudioContext();
    }

    public async componentDidMount() {

        DetectRTC.load(() => {

            log.info(JSON.stringify(DetectRTC));

            if (DetectRTC.isWebRTCSupported === false)
                log.warn(this.resources.clientApp.error_browserNotSupported);
            else if (DetectRTC.hasWebcam === false)
                log.warn(this.resources.clientApp.error_localMedia);

        });

        const stream = this.initLocalStream();

        if (await stream)
            await this.tryStartConferenceAsync();
        else
            this.setState({ noMediaDetected: true, showNoMediaPopup: true })

        window.addEventListener("resize", this.layoutVideos);
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.layoutVideos);
    }

    public async componentDidUpdate() {
        if (this.localStream) {
            const stream = await this.localStream;

            if (this.localVideo && !this.localVideo.srcObject) {
                this.localVideo.srcObject = stream;
                this.localVideo.volume = 0;

                await this.localVideo.play();
            }
        }

        await this.attachPeerVideos();

        this.layoutVideos();
    }

    private layoutVideos() {
        const options = {
            maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
            minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
            fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
            alignItems: "center", // Can be 'start', 'center' or 'end'. Determines where to place items when on a row or column that is not full
            bigClass: "OT_big", // The class to add to elements that should be sized bigger
            bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
            bigFixedRatio: false, // fixedRatio for the big ones
            bigAlignItems: "center", // How to align the big items
            smallAlignItems: "center", // How to align the small row or column of items if there is a big one
            bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
            bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
            bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
            animate: false, // Whether you want to animate the transitions
            window: window, // Lets you pass in your own window object which should be the same window that the element is in
            ignoreClass: "OT_ignore", // Elements with this class will be ignored and not positioned. This lets you do things like picture-in-picture
        };

        const layoutElem = document.getElementById("feed-layout");
        if (layoutElem) {
            const layout = initLayoutContainer(layoutElem, options);

            setTimeout(() => { layout.layout(); }, 300);
        }
    }

    private configureAudioContext() {
        window.addEventListener("mousedown", this.audioContextGestureHandler);
        window.addEventListener("touchstart", this.audioContextGestureHandler);
        try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            this.audioContext = new AudioContext();
        } catch (e) {
            alert('Web Audio API not supported.');
        }
    }

    /**
     *
     * @desc Handler that will be called when the user clicks or touches the application.
     * @memberof ConferenceRoom
     */
    public audioContextGestureHandler = () => {
        if (this.audioContext == null || this.audioContext.state !== "running") {
            this.audioContext = new AudioContext();
            this.audioContext.resume();
            window.removeEventListener("mousedown", this.audioContextGestureHandler);
            window.removeEventListener("touchstart", this.audioContextGestureHandler);
            Object
                .keys(this.peers)
                .forEach(peerId => {
                    if (this.streams[peerId] && this.streams[peerId]?.length) {
                        const stream: MediaStream[] = this.streams[peerId]!;
                        this.voiceDetector[peerId] = new VoiceAudioDetector({
                            source: this.audioContext?.createMediaStreamSource(stream[0]),
                            voiceStartCallback: () => this.setState(s => ({ ...s, currentPeerId: s.isLocalUserTalking ? s.currentPeerId : peerId })),
                            voiceStopCallback: () => { },
                        });
                    }
                });
        }
    }

    public render() {
        const generateToggleButton = (type: IconType, toggledType: IconType, handler: () => void, toggled: boolean) => (
            <div>
                <button
                    id={`conferenceRoomButton${IconType[toggled ? toggledType : type]}`}
                    className="uiButton"
                    onClick={handler}
                >
                    <Icon type={toggled ? toggledType : type} />
                </button>
            </div>
        )

        if (this.state.showErrorMessage)
            return <ErrorComponent
                errorMessage={<div>
                    <p>{this.resources.clientApp.conference_userDoesNotWantToContinueMessage}</p>
                    <p>{this.resources.clientApp.conference_rejoinConsultationIfMistakeMessage}</p>
                    <div className="text-center">
                        <Button onClick={() => window.location.reload()}>{this.resources.clientApp.buttonLabel_joinConsultation}</Button>
                    </div>
                </div>}
            />

        return (
            <div className="global-container">
                <div className="video-container">
                    {
                        this.state.noMediaDetected &&
                        this.state.showNoMediaPopup &&
                        this.renderNoStreamDetected()
                    }
                    {
                        !this.state.localStreamError &&
                        <video
                            id="feedAside"
                            className={`feed-aside ${this.state.cameraFacingMode}`}
                            ref={(video: HTMLVideoElement) => this.localVideo = video}
                            autoPlay={true}
                            playsInline={true}
                            muted={true}
                        />
                    }
                    {
                        !this.state.conferenceError &&
                        this.renderPeers()
                    }
                    <div className="video-overlay" />
                    {this.renderDebugInfo()}
                </div>
                <div className="ui-controls">
                    {generateToggleButton(IconType.VideoCamOn, IconType.VideoCamOff, this.handleCameraButtonClick, !this.state.isCameraEnabled)}
                    {generateToggleButton(IconType.MicOn, IconType.MicOff, this.handleMicrophoneButtonClick, !this.state.isMicrophoneEnabled)}
                    <div>
                        <button
                            id={"conferenceRoomButtonHangUp"}
                            className={"uiButton hang-up-button"}
                            onClick={this.handleHangupButtonClickAsync}
                        >
                            <Icon type={IconType.PhoneDisabledIcon} style={{ fill: "white" }} />
                        </button>
                    </div>
                    {generateToggleButton(IconType.CameraBack, IconType.CameraFront, this.handleFlipButtonClick, this.state.cameraFacingMode !== "user")}
                    {
                        Object.keys(this.peers).length > 1 &&
                        generateToggleButton(IconType.SingleUser, IconType.MultipleUsers, this.handleMultipleUserToggle, this.state.isSingleUserEnabled)
                    }
                    {generateToggleButton(IconType.FullScreen, IconType.FullScreenExit, this.handleFullscreenButtonClick, this.state.isFullscreen)}
                </div>
            </div>
        );
    }

    private renderPeers(): JSX.Element {
        const entries = Object.entries(this.peers);

        return <div id="feed-layout" className="feed-section">
            {!(entries && entries.length) &&
                <div className="feed-message OT_ignore">
                    {this.resources.clientApp.conference_waitingForConnection}
                </div>
            }
            {
                // We only want to render in single user mode (with audio detection) when we have more than one user connected.
                (entries && entries.length > 1) &&
                this.state.isSingleUserEnabled &&
                <video
                    className="feed-peer"
                    ref={(video: HTMLVideoElement) => this.currentSpeakerVideo = video}
                    autoPlay={true}
                    playsInline={true}
                />
            }
            {
                 // We only want to render in multi user mode (with no audio detection) when we only have 1 user or we set it directly.
                (entries && entries.length) &&
                (!this.state.isSingleUserEnabled || entries.length === 1) &&
                entries.map(entry => {
                    const [peerId,] = entry;

                    return <video className="feed-peer"
                        key={peerId}
                        ref={(video: HTMLVideoElement) => this.videos[peerId] = video}
                        autoPlay={true}
                        playsInline={true}
                    />;
                })
            }
        </div>;
    }

    private renderNoStreamDetected() {
        return <Popup
            id="NoStreamDetectedPopup"
            show={true}
            centered
            closeButton={{
                id: "noStreamDetectedPopupCancelButton",
                className: "btn btn-cancel",
                label: this.resources.clientApp.buttonLabel_cancel,
                onClick: () => {
                    window.close()
                    this.setState({ showNoMediaPopup: false, showErrorMessage: true });
                }
            }}
            okButton={{
                id: "noStreamDetectedPopupOkButton",
                variant: "primary",
                label: this.resources.clientApp.buttonLabel_continue,
                onClick: () => this.setState({ showNoMediaPopup: false }, this.tryStartConferenceAsync)
            }}
        >
            <p>{this.resources.clientApp.conference_noMediaPopup_noMediaDetectedMessage}</p>
            <p>{this.resources.clientApp.conference_noMediaPopup_doesUserWishToContinueMessage}</p>
        </Popup>
    }

    private handleMultipleUserToggle() {
        this.setState(s => ({ ...s, isSingleUserEnabled: !s.isSingleUserEnabled }))
    }

    private handleFullscreenButtonClick() {
        if (window !== window.parent) {
            window.parent.postMessage("fullScreen", "*")
        } else {
            if (!screenfull.isEnabled)
                return;

            if (this.state.isFullscreen)
                screenfull.exit();
            else
                screenfull.request();
        }

        this.setState({
            isFullscreen: !this.state.isFullscreen
        });
    }

    private handleCameraButtonClick() {

        this.setState({
            isCameraEnabled: !this.state.isCameraEnabled
        },
            () => this.localStream.then(localStream => {
                if (localStream === null)
                    return;

                const tracks = localStream.getVideoTracks();

                if (!this.state.isCameraEnabled)
                    tracks.forEach((track) => {
                        track.enabled = false;
                    });
                else
                    tracks.forEach((track) => {
                        track.enabled = true;
                    });
            })
        );
    }

    private handleMicrophoneButtonClick() {
        this.setState({
            isMicrophoneEnabled: !this.state.isMicrophoneEnabled
        },
            () => this.localStream.then(localStream => {
                if (localStream === null)
                    return;

                const tracks = localStream.getAudioTracks();

                if (!this.state.isMicrophoneEnabled) {
                    tracks.forEach((track) => {
                        track.enabled = false;
                    });
                } else {
                    tracks.forEach((track) => {
                        track.enabled = true;
                    });
                }
            })
        );
    }

    private async handleHangupButtonClickAsync() {
        if (window != window.parent)
            window.parent.postMessage("hangUp", "*");

        this.props.clearSession();

        const stream = await this.localStream;
        if (stream)
            stream.getTracks().forEach((track) => {
                track.stop();
            });

        this.hubConnection?.stop();

        if (this.peers) {
            Object.entries(this.peers).forEach((entry) => {
                let [peerId, peer] = entry;

                this.streams[peerId]?.forEach(stream => {
                    stream.getTracks().forEach(t => t.stop());
                });
                delete this.streams[peerId];
                delete this.videos[peerId];
                delete this.voiceDetector[peerId];

                peer?.destroy();
                peer = null;
            });
        }

        this.peers = {};
        this.videos = {};
        this.streams = {};
        this.voiceDetector = {};

        window.close();
    }

    private handleFlipButtonClick() {
        this.setState(s => {
            return {
                cameraFacingMode: s.cameraFacingMode === "environment"
                    ? "user"
                    : "environment" as "user" | "environment"
            };
        },
            () => this.switchLocalCameraStreamAsync()
        );
    }

    private async tryStartConferenceAsync() {
        try {

            this.hubConnection = this.hubConnection || await this.startHubConnectionAsync();
            this.forceUpdate();

        } catch (error) {

            const message = typeof (error) !== "undefined" && error.message;

            log.error(`error ${message}`, error);

            this.setState({
                conferenceError: message || "An error has occured."
            });
        }
    }

    private async detectDevicesAsync() {

        let getDevicesSuccess = (devices: MediaDeviceInfo[]) => {

            let amountOfCameras = 0;
            if (devices) {
                devices.forEach((x: MediaDeviceInfo) => {
                    if (x.kind === "videoinput") {
                        amountOfCameras++;
                    }
                });
            }

            this.setState({
                amountOfCameras: amountOfCameras
            })
        };

        let getDevicesError = (error: Error) => {
            log.error(`Failed enumerating devices ${JSON.stringify(error)}`);
        }

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: ["user", "environment"] } })

        await navigator.mediaDevices.enumerateDevices().then(getDevicesSuccess).catch(getDevicesError);

        return stream;
    }

    private getCameraStreamAsync(): Promise<MediaStream> {
        const constraints: MediaStreamConstraints = {
            video: {
                facingMode: this.state.cameraFacingMode
            },
            audio: true
        }

        return navigator.mediaDevices.getUserMedia(constraints);
    }

    private initLocalStream(): Promise<MediaStream | null> {
        //stop all active streams
        if (this.localStream) {
            log.warn("Stream already initialized!");
            return this.localStream;
        }

        return this.detectDevicesAsync()
            .then(stream => {

                const audioTrack = stream.getAudioTracks()[0];
                const videoTrack = stream.getVideoTracks()[0];
                const localStream = new MediaStream(
                    [
                        audioTrack,
                        videoTrack
                    ]);

                this.setState({
                    videoTrackLabel: videoTrack?.label,
                    audioTrackLabel: audioTrack?.label
                })
                this.localVoiceDetector = new VoiceAudioDetector({
                    source: this.audioContext?.createMediaStreamSource(localStream),
                    voiceStartCallback: () => this.setState({ isLocalUserTalking: true }),
                    voiceStopCallback: () => this.setState({ isLocalUserTalking: false }),
                });
                return localStream;
            })
            .catch(error => {
                log.error(`errorInitLocalCameraStream ${error?.message}`);

                this.setState({
                    localStreamError: this.resources.clientApp.error_localMedia.replace(/{{hostname}}/gi, window.location.hostname)
                });

                return null;
            });
    }

    private async switchLocalCameraStreamAsync() {
        if (this.localStream === null)
            throw Error("Error in switchLocalCameraStreamAsync: localStream not found")

        const localStream = await this.localStream;

        if (localStream === null)
            return;

        localStream.getVideoTracks().forEach(track => {
            track.stop();
        });

        const stream = await this.getCameraStreamAsync();
        if (stream === null)
            throw Error("Error in switchLocalCameraStreamAsync: CameraStream not found")

        const oldVideoTrack = localStream.getVideoTracks()[0];
        const newVideoTrack = stream?.getVideoTracks()[0];

        if (!oldVideoTrack) {
            throw Error("Error in switchLocalCameraStreamAsync: oldVideoTrack not found")
        }
        if (!newVideoTrack) {
            throw Error("Error in switchLocalCameraStreamAsync: newVideoTrack not found")
        }
        Object.values(this.peers).forEach((peer) => {
            peer?.replaceTrack(oldVideoTrack, newVideoTrack, localStream);
        });

        localStream.removeTrack(oldVideoTrack);
        localStream.addTrack(newVideoTrack);

        this.setState({
            videoTrackLabel: newVideoTrack?.label
        })
    }

    private async startHubConnectionAsync(): Promise<HubConnection> {
        const url = new URL(window.location.href);
        url.pathname = "/signalr";
        if (!url.searchParams.has("roomId"))
            url.searchParams.append("roomId", this.props.match.params.roomId);

        const hubConnection = new HubConnectionBuilder()
            .configureLogging(new DefaultLogger())
            .withUrl(url.href)
            .build();

        try {
            await hubConnection.start();
        }
        catch (error) {
            log.error("Hub connection error", error);
            throw Error("Could not connect to hub.");
        }

        const conference = this;

        hubConnection.on("ConnectionAccepted", async (msg: string) => {

            const peerInfo = JSON.parse(msg) as PeerInfo;
            log.debug(`Message from SignalingHub : ConnectionAccepted. Local PeerId=${peerInfo.connectionId}`);
            this.setState({ localPeerId: peerInfo.connectionId });

        });

        hubConnection.on("NewUserArrived", (msg: string) => {

            const peerInfo = JSON.parse(msg) as PeerInfo;
            log.debug(`Message from SignalingHub : NewUserArrived. Remote PeerId=${peerInfo.connectionId}`);

            conference.createPeerAsync(peerInfo.connectionId, true);
        });

        hubConnection.on("SendSignal", (peerId: string, signal: SignalData) => {

            log.debug(`Message from SignalingHub : Signal from peer=${peerId}`);

            conference.createPeerAsync(peerId, false)
                .then(peer => peer && conference.signalPeer(peerId, peer, signal));
        });

        hubConnection.on("UserDisconnect", async (peerId: string) => {

            log.debug(`Message from SignalingHub : Peer=${peerId} disconnected`);

            let peer = conference.peers[peerId];

            try {
                const stream = await this.localStream
                if (stream)
                    peer?.removeStream(stream);
            } catch {
                // tried
            }

            peer?.removeAllListeners();
            peer?.end();
            peer?.destroy();
            peer = null;

            delete conference.peers[peerId];
            delete conference.videos[peerId];
            delete conference.streams[peerId];
            delete conference.voiceDetector[peerId];

            this.setState(s => ({
                ...s,
                currentPeerId: Object.keys(conference.peers).length ? Object.keys(conference.peers)[0] : "",
                isSingleUserEnabled: Object.keys(conference.peers).length <= 1 ? false : s.isSingleUserEnabled
            }));
        });

        // notify signaling hub that we are a new user
        await hubConnection.invoke("NewUser");
        return hubConnection;
    }

    private attachPeerVideos() {
        if (this.state.isSingleUserEnabled && Object.keys(this.peers).length > 1) {
            if (!this.state.currentPeerId && !this.currentSpeakerVideo)
                return;

            this.streams[this.state.currentPeerId]?.forEach(async stream => {
                if (this.currentSpeakerVideo == null || (this.currentSpeakerVideo.srcObject && this.currentSpeakerVideo.srcObject === stream))
                    return;

                log.debug("setting peer video stream", this.state.currentPeerId, stream);
                Object.entries(this.peers)
                    .map(entry => {
                        const [peerId,] = entry;
                        return this.streams[peerId]?.map(stream => stream.getAudioTracks());
                    })
                    .filter(x => !!x)
                    .flat<MediaStreamTrack>(2)
                    .forEach(x => stream.addTrack(x));

                this.currentSpeakerVideo.setAttribute("data-peer-id", this.state.currentPeerId);
                this.currentSpeakerVideo.srcObject = stream;

                await this.currentSpeakerVideo.play();
            });
        } else {
            Object.entries(this.peers).forEach(async (entry) => {
                const [peerId,] = entry;

                const video = this.videos[peerId];

                if (!video) return;

                this.streams[peerId]?.forEach(stream => {
                    if (video.srcObject && video.srcObject === stream) return;

                    log.debug("setting peer video stream", peerId, stream);

                    video.setAttribute("data-peer-id", peerId);
                    video.srcObject = stream;

                    video.play();
                });
            });
        }

    }

    private getIceServersAsync(): Promise<ICEServers[] | null> {
        if (this.getIceServersPromise && Date.now() < this.lastIceCredentialsFetch + 5000)
            return this.getIceServersPromise;

        log.debug('fetching ice servers');

        this.lastIceCredentialsFetch = Date.now();

        this.getIceServersPromise = fetch("/iceservers")
            .then(response => {
                if (response.status === 200)
                    return response.json();

                this.setState({
                    conferenceError: "An error has occured."
                });

                return null;
            })
            .then(body => body as ICEServers[])
            .catch(error => {
                const message = typeof (error) !== "undefined" && error.message;

                log.error(`error fetching ice servers ${message}`, error);

                this.setState({
                    conferenceError: message || "An error has occured."
                });

                return null;
            });


        return this.getIceServersPromise;
    }

    private async createPeerAsync(peerId: string, initiator: boolean): Promise<Instance> {
        let peer = this.peers[peerId];
        if (peer)
            return peer;

        const iceServers = await this.getIceServersAsync();
        const stream = await this.localStream;

        peer = this.peers[peerId];
        if (peer)
            return peer;

        if (initiator)
            log.debug('INITIATOR PEER CREATION----');

        log.debug('ice servers:' + JSON.stringify(iceServers));

        peer = new SimplePeer({
            initiator: initiator,
            stream: stream ? stream : undefined,
            config: {
                iceServers: iceServers
            }
        });
        this.peers[peerId] = peer;

        const conference = this;
        peer.on("signal",
            (signal: SignalData) => {

                if (signal.type === "offer")
                    log.debug(`Message from peer=${peerId}: received offer signal". type=${signal.type} sdp=${truncateString(signal.sdp.replace(/\n/g, " "))}`);
                else
                    log.debug(`Message from peer=${peerId}: received signal". candidate=${JSON.stringify(signal.candidate)}`);

                if (!conference.hubConnection)
                    throw Error("No Hub Connection");

                conference.hubConnection.send("SendSignal", JSON.stringify(signal), peerId);

            });

        peer.on("stream",
            (peerStream: MediaStream) => {

                log.debug(`Message from peer=${peerId}: stream received`);

                const peerStreams = conference.streams[peerId];
                if (peerStreams)
                    peerStreams.push(peerStream);
                else
                    conference.streams[peerId] = [peerStream];

                if (this.voiceDetector[peerId]) {
                    delete this.voiceDetector[peerId];
                }
                this.voiceDetector[peerId] = new VoiceAudioDetector({
                    source: this.audioContext?.createMediaStreamSource(peerStream),
                    voiceStartCallback: () => this.setState(s => ({ ...s, currentPeerId: s.isLocalUserTalking ? s.currentPeerId : peerId })),
                    voiceStopCallback: () => { },
                });
                if (Object.keys(conference.streams).length === 1)
                    conference.setState({ currentPeerId: peerId })
                else
                    conference.forceUpdate();
            });

        peer.on("connect",
            () => {

                log.debug(`Message from peer=${peerId}: connected`);
                conference.forceUpdate();
            });


        peer.on("error",
            (e) => {
                log.debug(`Message from peer=${peerId}: error`, e);
            });

        if (Object.keys(this.peers).length === 1)
            // Set current peer id to first one that connects.
            this.setState({ currentPeerId: peerId })
        else
            this.forceUpdate();

        return peer;
    }

    private signalPeer(peerId: string, peer: Instance, data: any) {

        try {
            log.debug(`Sending direct signal message to peer=${peerId}.`);
            peer.signal(data);
        } catch (e) {

            log.error("signal error", e);
        }
    }

    private renderDebugInfo() {
        if (window.location.hash !== "#debug") return null;

        const localInfo = this.state.localPeerId;

        return (
            <div className="debug-info">

                <div>
                    <span style={{ fontWeight: "bold" }}> Selected Camera Device: </span>
                </div>
                <div> {this.state.videoTrackLabel} </div>

                <div>
                    <span style={{ fontWeight: "bold" }}> Selected Microphone Device: </span>
                </div>
                <div> {this.state.audioTrackLabel} </div>

                <div>
                    <span style={{ fontWeight: "bold" }}> Local Peer Id: </span>
                </div>
                <div> {localInfo} </div>
                <div>
                    <span style={{ fontWeight: "bold" }}> Remote Peer Ids: </span>
                </div>
                {
                    Object.keys(this.peers).map(peerId => {
                        return <div key={peerId}> {peerId} </div>;
                    })
                }
            </div>
        );
    }

    private configureRemoteLogging(roomId: string) {

        const rawJSONFormat = (log: any) => {
            return log;
        };

        const defaults = {
            url: `/logs/add?roomId=${roomId}`,
            method: 'POST',
            headers: {},
            token: '',
            onUnauthorized: (failedToken: any) => { log.error(`Authentication failed: ${failedToken}`); },
            timeout: 0,
            interval: 2000,
            level: 'debug',
            backoff: {
                multiplier: 2,
                jitter: 0.1,
                limit: 30000,
            },
            capacity: 500,
            stacktrace: {
                levels: ['trace', 'warn', 'error'],
                depth: 3,
                excess: 0,
            },
            timestamp: () => new Date().toISOString(),
            format: rawJSONFormat
        };

        remote.apply(log, defaults);
    }
}
ConferenceRoom.contextType = LanguageContext;


const truncateString = (str: string, maxLength: number = 50) => {
    if (!str) return null;
    if (str.length <= maxLength) return str;
    return `${str.substring(0, maxLength)}...`;
};

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(ConferenceRoom as any);