import React, { MouseEvent } from "react";
import LanguageContext from "./LanguageContext";

export default function LanguageSwitcher(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {

    const handleClick = (e: MouseEvent, setLanguage: (language: string) => void) => {
        const toLanguage: string = (e.target as any)?.value;

        if (toLanguage)
            setLanguage(toLanguage);
    }

    const getBestLanguageMatch = (lang: string) => {
        const upperLang = lang.toUpperCase();

        return navigator.languages
            .find(p => p.toUpperCase().startsWith(upperLang))
            || lang;
    }

    return (
          <LanguageContext.Consumer>
            {({ language, setLanguage }) => (
                <div {...props}>
                    {!language.startsWith("fr")
                        && <button className="languageSwitch" onClick={e => handleClick(e, setLanguage)} value={getBestLanguageMatch("fr")}>FR</button>
                    }
                    {!language.startsWith("en")
                        && <button className="languageSwitch" onClick={e => handleClick(e, setLanguage)} value={getBestLanguageMatch("en")}>EN</button>
                    }
                    {!language.startsWith("es")
                        && <button className="languageSwitch" onClick={e => handleClick(e, setLanguage)} value={getBestLanguageMatch("es")}>ES</button>
                    }
                </div>
            )}
          </LanguageContext.Consumer>
    )
};
