export interface ILocalizedString {
    defaultValue: string;
    values: { [index: string]: string };
    getLocalizedValue(lang: string): string;
}

export class LocalizedString implements ILocalizedString {
    public defaultValue: string = "";
    public values: { [index: string]: string } = {};

    constructor(init?: Partial<ILocalizedString>) {
        Object.assign(this, init);
        const newValues: { [index: string]: string } = {};
        Object.keys(this.values).forEach(x => newValues[x.toLowerCase()] = this.values[x]);
        this.values = newValues;
    }

    public getLocalizedValue(lang: string): string {
        if (!lang)
            return this.defaultValue;
        
        if (this.values[lang])
            return this.values[lang];

        let langCode = lang.toLowerCase();

        while (langCode.length) {
            const pos = langCode.lastIndexOf("-");
            if(pos <= 0 || this.values[langCode])
                break;
            else
                langCode = langCode.substring(0, pos);           
        }

        return this.values[langCode] || this.defaultValue;
    }
}