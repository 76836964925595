import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import Cookies from "js-cookie";

//// -----------------
//// STATE - This defines the type of data maintained in the Redux store.
export interface LoginState {
    authenticationResult: "success" | "fail" | null;
    isLoggingIn: boolean;
    invalidCode?: string;
    userClaims?: { [claim: string]: string | string[] | undefined }
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface ClearSession {
    type: "CLEAR_SESSION";
}

interface TryAuthenticateAction {
    type: "TRY_AUTHENTICATE";
}

interface AuthenticateResult {
    type: "AUTHENTICATE_RESULT";
    authenticationResult: "success" | "fail";
    invalidCode?: string;
    userClaims?: { [claim: string]: string | string[] | undefined }
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = TryAuthenticateAction | ClearSession | AuthenticateResult;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    tryAuthenticate: (roomId: string, authCode: string): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {

            const appState = getState();

            // stop if the user is currently logging in
            if (appState.login && appState.login.isLoggingIn)
                return;

            const requestOptions = {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({
                    authCode: authCode
                })
            };

            dispatch({ type: "TRY_AUTHENTICATE" });

            let response: Response;
            try {
                response = await fetch(`/app/login/${roomId}`, requestOptions);

            } catch (error) {
                dispatch({
                    type: "AUTHENTICATE_RESULT",
                    authenticationResult: "fail"
                });
                return;
            }

            if (response.status === 200)
                dispatch({
                    type: "AUTHENTICATE_RESULT",
                    authenticationResult: "success",
                    userClaims: await response.json()
                });
            else
                dispatch({
                    type: "AUTHENTICATE_RESULT",
                    authenticationResult: "fail",
                    invalidCode: authCode
                });
        },

    clearSession: (): AppThunkAction<KnownAction> =>
        (dispatch) => {
            dispatch({
                type: "AUTHENTICATE_RESULT",
                authenticationResult: "fail"
            });

        },

    monitorCookie: (): AppThunkAction<KnownAction> =>
        (dispatch) => {
            dispatch({
                type: "AUTHENTICATE_RESULT",
                authenticationResult: "fail"
            });
        }
};

const getInitialState = (): LoginState => {
    const userClaims = Cookies.get(".telemed.medfar.claims");

    if (!userClaims)
        return {
            isLoggingIn: false,
            authenticationResult: null
        };

    return {
        isLoggingIn: false,
        authenticationResult: "success",
        userClaims: JSON.parse(userClaims)
    };
}

//// ----------------
//// REDUCER - For a given state and action, returns the new state.
export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return getInitialState();
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TRY_AUTHENTICATE":
            return {
                authenticationResult: null,
                isLoggingIn: true,
                userClaims: {}
            };

        case "CLEAR_SESSION":
            return {
                authenticationResult: null,
                isLoggingIn: false,
                userClaims: {}
            };

        case "AUTHENTICATE_RESULT":
            // Only accept the incoming data if it user is logging in
            if (state.isLoggingIn) {
                return {
                    authenticationResult: action.authenticationResult,
                    invalidCode: action.invalidCode,
                    isLoggingIn: false,
                    userClaims: action.userClaims
                };
            }
            break;
    }

    return state;
};
