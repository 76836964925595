import React, { Component } from "react";
import { Header } from "./Header"


export class Layout extends Component {
  static displayName = Layout.name;

    render() {
      return (
          <div className="global-container">
              <div className="header">
                  <Header title={"Medfar Solutions Telemed"} />
              </div>
              <div className="main">
                  {this.props.children}
              </div>
          </div>
    );
  }
}
