import React, { PureComponent } from "react";
import { Layout } from "./Layout";

export interface ErrorProps {
    errorMessage: React.ReactNode;
}

export default class Error extends PureComponent<ErrorProps> {
    render() {
        return (
            <Layout>
                <div className={"content-container centered"}>{this.props.errorMessage}</div>
            </Layout>
        );
    }
}
