import { PureComponent } from "react";
import Resources, { getResources } from "../modules/localization/Resources";
import LanguageContext from "./LanguageContext";


abstract class BaseReactComponent<P, S> extends PureComponent<P, S, { language: string; }> {
    public get resources(): Resources {
        return getResources(this.context.language);
    }
}

BaseReactComponent.contextType = LanguageContext;

export default BaseReactComponent;
