import { IClientAppResource } from "./ClientAppResources";

const clientApp: IClientAppResource =  {
    buttonLabel_cancel: "Cancelar",
    buttonLabel_continue: "Continuar",
    buttonLabel_joinConsultation: "Unirse a la consulta",
    conference_noMediaPopup_doesUserWishToContinueMessage: "¿Todavía desea unirse a la teleconsulta?",
    conference_noMediaPopup_noMediaDetectedMessage: "No se detectó ningún micrófono o cámara en su dispositivo.",
    conference_rejoinConsultationIfMistakeMessage: "Si esta no era su intención, por favor únase a la consulta usando el botón de abajo.",
    conference_userDoesNotWantToContinueMessage: "Eligió no seguir sin su cámara y su micrófono.",
    conference_videoFeedNotAvailable: "Video no disponible",
    conference_waitingForConnection: "Esperando a que los otros asistentes se conecten…",
    error_browserNotSupported: "Lo sentimos, no podemos comenzar la conferencia. Utilice Chrome, Firefox, iOS 11, Android 5 o superior, Safari 11 o superior.",
    error_connection: "Lo sentimos, no podemos comenzar la conferencia. Verifique su conexión a Internet y espere unos momentos antes de volver a intentarlo.",
    error_general: "Lo sentimos, algo salió mal al comenzar la conferencia. Espere unos momentos antes de volver a intentarlo.",
    error_localMedia: "Lo sentimos, no podemos comenzar la conferencia. Asegúrese de que {{hostname}} pueda acceder a su cámara y micrófono.",
    error_login: "Lo sentimos, el código de acceso proporcionado no es válido.",
    error_roomNotFound: "Lo sentimos, no podemos encontrar la conferencia. Es posible que la conferencia no haya comenzado o que el enlace haya expirado.",
    login_button: "Entrar",
    login_codePlaceholder: "Código de acceso",
    login_disclaimer: "Al introducir su código de acceso de MYLE Telemed abajo, usted certifica :\n*    Que ha alcanzado la edad para dar su consentimiento a los servicios de telesalud en la jurisdicción en la que se encuentra, o que es el representante legal de un paciente que aún no ha alcanzado esa edad según lo definido por las leyes y reglamentos aplicables en la jurisdicción en la que se encuentra;\n*    Consentimiento para que {{Organization Name}} y el personal tratante de esta instalación realicen una o más consultas en relación con su actual problema de salud;\n*    Comprender que {{Organization Name}} utiliza todos los medios tecnológicos posibles para contrarrestar los riesgos de violación de la confidencialidad y/o la pérdida de definición de la imagen al prestar servicios de telesalud;\n*    Comprender que, aunque MYLE Telemed no hace copias de seguridad ni grabaciones de la sesión, el personal de {{Organization Name}} puede almacenar información confidencial electrónicamente para fines de registro;\n*    Aceptar esta forma de consulta y comprender que tiene limitaciones relacionadas con los medios de comunicación utilizados, en particular con respecto a la seguridad y confidencialidad del entorno físico en el que se prestarán los servicios de telesalud.",
    login_disclaimerLabel: "Certifico que soy consciente de los riesgos y limitaciones de los servicios de telesalud y acepto participar en la sesión de MYLE Telemed.",
    login_prompt: "Ingrese el código de acceso provisto por su médico:"
};
export default clientApp;