import React from "react";
import ReactMarkdown from "react-markdown";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import * as _ from "lodash";
import { Layout } from "./Layout";
import { ApplicationState } from "../store";
import * as LoginStore from "../store/Login";
import * as RoomStore from "../store/Room";
import { ConferenceParams } from "./ConferenceRoomRoute";
import LanguageContext from "./LanguageContext";
import BaseReactComponent from "./BaseReactComponent";


interface LoginState {
    authCode: string;
    maskedAuthCode: string;
    authCodeValid?: boolean;
    acceptDisclaimer?: boolean;
    invalidCodes: string[];
}

type LoginProps = {
    login: LoginStore.LoginState;
    room: RoomStore.RoomState;
}
    & typeof LoginStore.actionCreators
    & RouteComponentProps<ConferenceParams>;


class Login extends BaseReactComponent<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.validateCode = _.debounce(this.validateCode.bind(this), 250);

        this.state = {
            authCode: "",
            maskedAuthCode: "",
            invalidCodes: []
        };
    }

    static isCodeValid(authCode: string, invalidCodes: string[]) {
        const code = authCode.split(" ").join('');
        return !!code.match(/^\d{6}$/) && !invalidCodes.includes(code)
    }

    static getDerivedStateFromProps(nextProps: LoginProps, prevState: LoginState) {
        if (nextProps.login.invalidCode) {
            const invalidCodes = [...prevState.invalidCodes, nextProps.login.invalidCode];

            return {
                invalidCodes: invalidCodes,
                authCodeValid: Login.isCodeValid(prevState.authCode, invalidCodes)
            };
        }

        return null;
    }

    validateCode() {
        this.setState(s => {
            return {
                authCodeValid: Login.isCodeValid(s.authCode, s.invalidCodes)
            };
        });
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        switch (event.target.name) {
            case "authCode":
                const authCode = (event.target.value as string)
                    .split(/\D/g)
                    .join('');
                
                const maskedAuthCode = authCode.length > 3
                    ? `${authCode.substr(0, 3)} ${authCode.substr(3, 3)}`
                    : authCode;


                this.setState({
                    authCode: authCode,
                    maskedAuthCode: maskedAuthCode
                });

                this.validateCode();
                break;

            case "acceptDisclaimer":
                this.setState({
                    acceptDisclaimer: event.target.checked
                });
                break;
        }
    }

    private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (this.state.authCode)
            this.props.tryAuthenticate(
                this.props.match.params.roomId,
                this.state.authCode
            );
    }

    render() {
        return (
            <LanguageContext.Consumer>
                {({ language }) => {
                    const resources = this.resources;

                    return (
                        <Layout>
                        <div className="content-container centered">
                            <form className="login-form" onSubmit={this.handleSubmit} autoComplete="off">

                                <div className="login-form-row">
                                    <ReactMarkdown
                                        source={resources.clientApp.login_disclaimer.replace(/{{Organization Name}}/gmi,
                                            this.props.room[this.props.match.params.roomId].siteNameLocalized?.getLocalizedValue(this.context.language)
                                            || window.location.hostname.split(".")[0]
                                        )}
                                    />
                                </div>

                                <div className="login-form-row">
                                    <label className="form-control-label">
                                        {resources.clientApp.login_prompt}
                                    </label>
                                </div>

                                <div className="login-form-row">
                                    {this.state.authCodeValid === false &&
                                        <span className="invalid">{resources.clientApp.error_login}</span>
                                    }
                                    <input
                                        id="accessCodeInput"
                                        name="authCode"
                                        inputMode="numeric"
                                        autoComplete="off"
                                        className={`form-control${this.state.authCodeValid === false ? " invalid" : ""}`}
                                        placeholder={resources.clientApp.login_codePlaceholder}
                                        onChange={this.handleChange}
                                        value={this.state.maskedAuthCode}/>
                                </div>
                        
                                <div className="login-form-row">
                                    <label className="form-control-label" htmlFor="acceptDisclaimer">
                                        <input
                                            id="acceptDisclaimer"
                                            type="checkbox"
                                            name="acceptDisclaimer"
                                            value="1"
                                            onChange={this.handleChange}/>
                                        {resources.clientApp.login_disclaimerLabel}
                                    </label>
                                </div>

                                <div className="login-form-row">
                                    <input
                                        className="btn primary full-width"
                                        type="submit"
                                        value={resources.clientApp.login_button}
                                        disabled={!this.state.authCodeValid || !this.state.acceptDisclaimer || this.props.login.isLoggingIn}/>
                                </div>

                            </form>
                        </div>
                        </Layout>
                    );
                }
              }
            </LanguageContext.Consumer>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            login: state.login,
            room: state.room
        };
    }, // Selects which state properties are merged into the component's props
    LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(Login as any);
