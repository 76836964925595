import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import { ClearSession } from "./Login";
import { LocalizedString } from "../models/LocalizedString";
import { ConferenceRoomData } from "../models/ConferenceRoomData";
//// -----------------
//// STATE - This defines the type of data maintained in the Redux store.

export interface RoomInfo {
    state: "found" | "notFound" | "error" | "pending" | undefined;
    siteNameLocalized: LocalizedString | null
}

export interface RoomState {
    [id: string]: RoomInfo
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface TryGetRoom {
    type: "TRY_GET_ROOM";
    roomId: string;
}

interface GotRoomResult {
    type: "GOT_ROOM_RESULT";
    roomId: string;
    result: "found" | "notFound" | "error";
    siteNameLocalized: LocalizedString;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = TryGetRoom | GotRoomResult | ClearSession;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    tryGetRoom: (roomId: string): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {

            const appState = getState();

            if (appState.room && appState.room[roomId])
                return;

            dispatch({
                type: "TRY_GET_ROOM",
                roomId: roomId
            });

            let statusCode: number;
            let siteNameLocalized: LocalizedString = new LocalizedString();
            try {
                const response = await fetch(`/app/conference/${roomId}`);
                const data: ConferenceRoomData = await response.json();

                siteNameLocalized = new LocalizedString(data.siteNameLocalized);

                statusCode = response.status;
            } catch (error) {
                console.log(error);
                statusCode = error && error.status;
            }

            switch (statusCode) {
                case 200:
                    dispatch({
                        type: "GOT_ROOM_RESULT",
                        roomId,
                        result: "found",
                        siteNameLocalized
                    });
                    break;

                case 401:
                    dispatch({
                        type: "CLEAR_SESSION"
                    });
                    dispatch({
                        type: "GOT_ROOM_RESULT",
                        roomId,
                        result: "found",
                        siteNameLocalized
                    });
                    break;

                case 404:
                    dispatch({
                        type: "GOT_ROOM_RESULT",
                        roomId: roomId,
                        result: "notFound",
                        siteNameLocalized
                    });
                    break;

                default:
                    dispatch({
                        type: "GOT_ROOM_RESULT",
                        roomId,
                        result: "error",
                        siteNameLocalized
                    });
                    break;
            }
        }
};

//// ----------------
//// REDUCER - For a given state and action, returns the new state.
export const reducer: Reducer<RoomState> = (state: RoomState | undefined, incomingAction: Action): RoomState => {
    if (state === undefined) {
        return {};
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TRY_GET_ROOM":
            return {
                ...state,
                [action.roomId]: {
                    state: "pending",
                    siteNameLocalized: null
                }                
            };

        case "GOT_ROOM_RESULT":
            return {
                ...state,
                [action.roomId]: {
                    state: action.result,
                    siteNameLocalized: action.siteNameLocalized
                }                
            };
    }

    return state;
};