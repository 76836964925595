import { IClientAppResource } from "./ClientAppResources";

const clientApp: IClientAppResource =  {
    buttonLabel_cancel: "Annuler",
    buttonLabel_continue: "Continuer",
    buttonLabel_joinConsultation: "Rejoindre la consultation",
    conference_noMediaPopup_doesUserWishToContinueMessage: "Souhaitez-vous tout de même rejoindre la téléconsultation?",
    conference_noMediaPopup_noMediaDetectedMessage: "Aucun microphone ou caméra n'a été détecté sur votre appareil.",
    conference_rejoinConsultationIfMistakeMessage: "Si ce n'était pas votre intention, veuillez rejoindre la consultation en utilisant le bouton ci-dessous.",
    conference_userDoesNotWantToContinueMessage: "Vous avez choisi de ne pas continuer sans votre caméra et votre micro.",
    conference_videoFeedNotAvailable: "Transmission vidéo non disponible",
    conference_waitingForConnection: "En attente des autres participants...",
    error_browserNotSupported: "Désolé, nous ne pouvons pas démarrer la conférence. Veuillez utiliser Chrome, Firefox, iOS 11, Android 5 ou supérieur, Safari 11 ou supérieur.",
    error_connection: "Désolé, nous ne pouvons pas démarrer la conférence. Veuillez vérifier votre connexion Internet et patientez quelques instants avant de réessayer.",
    error_general: "Désolé, une erreur s'est produite lors du démarrage de la conférence. Veuillez patienter quelques instants avant de réessayer.",
    error_localMedia: "Désolé, nous ne pouvons pas démarrer la conférence. Veuillez vous assurer que {{hostname}} est autorisé à accéder à votre caméra et à votre microphone.",
    error_login: "Désolé, le code d'accès fourni n'est pas valide.",
    error_roomNotFound: "Désolé, nous ne pouvons pas trouver la conférence. Il est possible que la conférence n'ait pas commencé ou que le lien ait expiré.",
    login_button: "Entrer",
    login_codePlaceholder: "Code d'accès",
    login_disclaimer: "En entrant votre code d'accès MYLE Télémed ci-dessous, vous attestez :\n* Avoir atteint l'âge vous permettant de consentir aux services de télésanté dans le territoire où vous vous trouvez, ou être le représentant légal d'un patient n'ayant pas encore atteint cet âge tel que défini par les lois et règlements applicables dans le territoire où il se trouve;\n* Consentir à ce que {{Organization Name}} et le personnel traitants de cet établissement effectuent une ou plusieurs consultations dans le cadre de votre présente problématique de santé;\n* Comprendre que {{Organization Name}} a recours à tous les moyens technologiques possibles pour contrer les risques de bris de confidentialité et ou de perte de définition des images lors de la prestation des services de télésanté;\n* Comprendre que bien que MYLE Télémed ne procède à aucune sauvegarde ou enregistrement de la session, le personnel traitant de {{Organization Name}} peut procéder à la sauvegarde de renseignements confidentiels sur support électronique à des fins de tenue de dossier;\n* Accepter cette forme de consultation et comprendre qu'elle comporte des limitations en lien avec les moyens de communication utilisés, particulièrement en ce qui a trait à la sécurité et la confidentialité de l'environnement physique dans lequel les services de télésanté seront prodigués.",
    login_disclaimerLabel: "J'atteste avoir pris connaissance des risques et limitations des services de télésanté et consens à participer à la session MYLE Télémed.",
    login_prompt: "Veuillez saisir le code d'accès fourni par votre clinicien:"
};

export default clientApp;