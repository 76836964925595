import { IClientAppResource } from "./ClientAppResources";

const clientApp: IClientAppResource = {
    buttonLabel_cancel: "Cancel",
    buttonLabel_continue: "Continue",
    buttonLabel_joinConsultation: "Join Consultation",
    conference_noMediaPopup_doesUserWishToContinueMessage: "Do you still wish to join the teleconsultation?",
    conference_noMediaPopup_noMediaDetectedMessage: "No microphone or camera was detected on your device.",
    conference_rejoinConsultationIfMistakeMessage: "If this was done by mistake, please join the teleconsultation using the button below.",
    conference_userDoesNotWantToContinueMessage: "You decided not to continue without using your camera and microphone.",
    conference_videoFeedNotAvailable: "Video feed not available",
    conference_waitingForConnection: "Waiting for the other attendees to connect…",
    error_browserNotSupported: "Sorry, we can't start the conference. Please use Chrome, Firefox, iOS 11, Android 5 or higher, Safari 11 or higher.",
    error_connection: "Sorry, we can't start the conference. Please check your internet connection and wait a few moments before trying again.",
    error_general: "Sorry, something went wrong when starting the conference. Please wait a few moments before trying again.",
    error_localMedia: "Sorry, we can't start the conference. Please make sure that {{hostname}} are allowed to access your camera and microphone.",
    error_login: "Sorry, the access code provided is not valid.",
    error_roomNotFound: "Sorry, we can't find the conference. It is possible that the conference has not started, or the link has expired.",
    login_button: "Enter",
    login_codePlaceholder: "Access Code",
    login_disclaimer: "By entering your MYLE Telemed access code below, you certify :\n* You have reached the age to consent to telehealth services in the jurisdiction in which you are located, or are the legal representative of a patient who has not yet reached that age as defined by the applicable laws and regulations in the jurisdiction in which he or she is located;\n* Consent for {{Organization Name}} and the treating personnel of this facility to conduct one or more consultations in connection with your present health problem;\n* Understand that {{Organization Name}} uses all possible technological means to counter the risks of breach of confidentiality and/or loss of image definition when providing telehealth services;\n* Understand that although MYLE Telemed does not make any backups or recordings of the session, {{Organization Name}} staff may store confidential information electronically for record-keeping purposes;\n* Accept this form of consultation and understand that it has limitations related to the means of communication used, particularly with respect to the security and confidentiality of the physical environment in which the telehealth services will be provided.",
    login_disclaimerLabel: "I certify that I am aware of the risks and limitations of telehealth services and agree to participate in the MYLE Telemed session.",
    login_prompt: "Please enter the access code provided by your clinician:"
};

export default clientApp;