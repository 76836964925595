import React from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";

import LanguageContext from "./LanguageContext";

import { getResources } from "../modules/localization/Resources";

import { ApplicationState } from "../store/index";
import * as LoginStore from "../store/Login";
import * as RoomStore from "../store/Room";


import ConferenceRoom from "./ConferenceRoom";
import Login from "./Login";
import Error from "./Error";

export interface ConferenceParams {
    roomId: string;
}

type ConferenceRoomRouteProps = {
        login: LoginStore.LoginState;
        room: RoomStore.RoomState;
    } 
    & typeof RoomStore.actionCreators
    & RouteComponentProps<ConferenceParams>;

class ConferenceRoomRoute extends React.PureComponent<ConferenceRoomRouteProps> {
    render() {
        return (
            <LanguageContext.Consumer>
                {({ language }) => {
                    const roomId = this.props.match.params.roomId;
                    const resources = getResources(language);

                    switch (this.props.room[this.props.match.params.roomId]?.state) {
                        case undefined:
                            this.props.tryGetRoom(roomId);

                            return <div className="appLoading"></div>;

                        case "pending":
                            return <div className="appLoading"></div>;

                        case "notFound":
                            return <Error errorMessage={resources.clientApp.error_roomNotFound}/>;

                        case "error":
                            return <Error errorMessage={resources.clientApp.error_general}/>;
                    }

                    if(!this.props.login)
                        return <Login {...this.props}/>;

                    if (this.props.login.authenticationResult !== "success") {
                        return <Login {...this.props}/>;
                    }

                    const roleClaimName = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
                    const roleClaims = this.props.login.userClaims && this.props.login.userClaims[roleClaimName];
                    if(roleClaims && (roleClaims === "clinicUser" || roleClaims.includes("clinicUser")))
                        return <ConferenceRoom {...this.props}/>;

                    const roomIdClaimName = "http://schemas.medfarsolutions.com/2020/04/claims/telemedroomid";
                    const roomClaims = this.props.login.userClaims && this.props.login.userClaims[roomIdClaimName];

                    if(roomClaims && (roomClaims === roomId || roomClaims.includes(roomId)))
                        return <ConferenceRoom {...this.props}/>;

                    return <Login {...this.props}/>;
                }
              }
            </LanguageContext.Consumer>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            login: state.login,
            room: state.room
        };
    },
    RoomStore.actionCreators
)(ConferenceRoomRoute as any);