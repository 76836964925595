import { Button, Modal, ButtonProps } from "react-bootstrap";
import * as React from "react";

interface IButtonProperties extends ButtonProps{
    id?: string;
    onClick: () => void;
    label: string;
    className?: string;
    style?: React.CSSProperties;
}

interface IPopupProps {
    id?: string;
    size?: 'sm' | 'lg' | 'xl';
    centered?: boolean;
    title?: string;
    show: boolean;
    closeButton: IButtonProperties;
    headerCloseButton?: boolean;
    okButton?: IButtonProperties;
    closeOnOutsideClick?: boolean;
}

export class Popup extends React.Component<IPopupProps, {}> {
    public render(): JSX.Element {
        return <Modal
            id={this.props.id}
            show={this.props.show}
            size={this.props.size}
            onHide={() => this.props.closeOnOutsideClick && this.props.closeButton.onClick()}
            centered={this.props.centered}
        >
            {
                this.props.title &&
                <Modal.Header closeButton={this.props.headerCloseButton}>
                    <Modal.Title>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {this.props.children}
            </Modal.Body>
            <Modal.Footer>
                {this.renderButton(this.props.closeButton)}
                {this.props.okButton && this.renderButton(this.props.okButton)}
            </Modal.Footer>
        </Modal>
    }

    private renderButton(props: IButtonProperties) {
        return <Button {...props}>{props.label}</Button>
    }
}