import clientAppResource from "./ClientAppResource"
import clientAppResource_fr from "./ClientAppResource.fr"
import clientAppResource_es from "./ClientAppResource.es"


export interface IClientAppResource {
    /**
     * Looks up a localize string similar to Cancel
     */
    buttonLabel_cancel: string;

    /**
     * Looks up a localize string similar to Continue
     */
    buttonLabel_continue: string;

    /**
     * Looks up a localize string similar to Join Consultation
     */
    buttonLabel_joinConsultation: string

    /**
     * Looks up a localize string similar to Do you still wish to join the teleconsultation? 
     */
    conference_noMediaPopup_doesUserWishToContinueMessage: string;
    
    /**
     * Looks up a localize string similar to No microphone or camera was detected on your device.
     */
    conference_noMediaPopup_noMediaDetectedMessage: string;

    /**
     * Looks up a localize string similar to If this was done by mistake, please join the teleconsultation using the button below.
     */
    conference_rejoinConsultationIfMistakeMessage: string;

    /**
     * Looks up a localize string similar to You decided not to continue without using your camera and microphone. If this was done by mistake, please join the teleconsultation using the button below.
     */
    conference_userDoesNotWantToContinueMessage: string;

    /**
     * Looks up a localized string similar to Video feed not available.
     */

    conference_videoFeedNotAvailable: string;

    /**
     * Looks up a localized string similar to Waiting for the other attendees to connect.
     */
    conference_waitingForConnection: string;

    /**
     * Looks up a localized string similar to Sorry, we can't start the conference. Please use Chrome, Firefox, iOS 11, Android 5 or higher, Safari 11 or higher..
     */
    error_browserNotSupported: string; 

    /**
     * Looks up a localized string similar to Sorry, we can't start the conference. Please check your internet connection and wait a few moments before trying again..
     */
    error_connection: string;

    /**
     * Looks up a localized string similar to Sorry, something went wrong when starting the conference. Please wait a few moments before trying again..
     */
    error_general: string;

    /**
     * Looks up a localized string similar to Sorry, we can't start the conference. Please make sure that {{hostname}} are allowed to access your camera and microphone..
     */
    error_localMedia: string;

    /**
     * Looks up a localized string similar to Sorry, the access code provided is not valid..
     */
    error_login: string;

    /**
     * Looks up a localized string similar to Sorry, we can't find the conference. It is possible that the conference has not started, or the link has expired..
     */
    error_roomNotFound: string;

    /**
     * Looks up a localized string similar to Enter.
     */
    login_button: string;

    /**
     * Looks up a localized string similar to Access Code.
     */
    login_codePlaceholder: string;
    
    /**
     * Looks up a localized string similar to By entering your MYLE Telemed access code below, you certify :\n* You have reached the age to consent to telehealth... .
     */
    login_disclaimer: string;
    
    /**
     * Looks up a localized string similar to I certify that I am aware of the risks and limitations of telehealth services and agree to participate in the MYLE Telemed session..
     */
    login_disclaimerLabel: string;

    /**
     * Looks up a localized string similar to Please enter the access code provided by your clinician:.
     */
    login_prompt: string;
}

const clientAppResources: {
    [language: string]: IClientAppResource;
} = {};

clientAppResources[""] = clientAppResource;
clientAppResources["fr"] = clientAppResource_fr as IClientAppResource;
clientAppResources["es"] = clientAppResource_es as IClientAppResource;

export default clientAppResources;
