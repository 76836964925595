import * as React from "react";
import { ReactComponent as CallEndIcon } from "../images/call_end-24px.svg";
import { ReactComponent as CallIcon } from "../images/call-24px.svg";
import { ReactComponent as CameraAltIcon } from "../images/camera_alt-24px.svg";
import { ReactComponent as CameraFrontIcon } from "../images/camera_front-24px.svg";
import { ReactComponent as CameraRearIcon } from "../images/camera_rear-24px.svg";
import { ReactComponent as FullScreenExitIcon } from "../images/fullscreen_exit-24px.svg";
import { ReactComponent as FullScreenIcon } from "../images/fullscreen-24px.svg";
import { ReactComponent as MicOffIcon } from "../images/mic_off-24px.svg";
import { ReactComponent as MicIcon } from "../images/mic-24px.svg";
import { ReactComponent as PhoneDisabledIcon } from "../images/phone_disabled-24px.svg";
import { ReactComponent as PhoneEnabledIcon } from "../images/phone_enabled-24px.svg";
import { ReactComponent as VideoCamOffIcon } from "../images/videocam_off-24px.svg";
import { ReactComponent as VideoCam } from "../images/videocam-24px.svg";
import { ReactComponent as SingleUserIcon } from "../images/single-user.svg";
import { ReactComponent as MultipleUsersIcon } from "../images/multiple-users.svg";

export enum IconType {
    Call,
    CallEnd,
    Camera,
    CameraFront,
    CameraBack,
    FullScreen,
    FullScreenExit,
    MicOn,
    MicOff,
    PhoneEnabled,
    PhoneDisabledIcon,
    VideoCamOn,
    VideoCamOff,
    SingleUser,
    MultipleUsers
}



const Icon = (props: { type: IconType, style?: React.SVGProps<SVGSVGElement> }): JSX.Element => {
    const { style } = props;

    switch (props.type) {
        case IconType.Call:
            return <CallIcon {...style} />
        case IconType.CallEnd:
            return <CallEndIcon {...style} />
        case IconType.Camera:
            return <CameraAltIcon {...style} />
        case IconType.CameraFront:
            return <CameraFrontIcon {...style} />
        case IconType.CameraBack:
            return <CameraRearIcon {...style} />
        case IconType.FullScreen:
            return <FullScreenIcon {...style} />
        case IconType.FullScreenExit:
            return <FullScreenExitIcon {...style} />
        case IconType.MicOn:
            return <MicIcon {...style} />
        case IconType.MicOff:
            return <MicOffIcon {...style} />
        case IconType.PhoneEnabled:
            return <PhoneEnabledIcon {...style} />
        case IconType.PhoneDisabledIcon:
            return <PhoneDisabledIcon {...style} />
        case IconType.VideoCamOn:
            return <VideoCam {...style} />
        case IconType.VideoCamOff:
            return <VideoCamOffIcon {...style} />
        case IconType.SingleUser:
            return <SingleUserIcon {...style} />
        case IconType.MultipleUsers:
            return <MultipleUsersIcon {...style} />
        default:
            return <div />;
    }
}
export default Icon;