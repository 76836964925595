import ClientAppResource, { IClientAppResource } from "./ClientAppResources/ClientAppResources";
import _ from "lodash";

export default class Resources {
    private static clientApp: {
        [language: string]: IClientAppResource
    } = {};

    private readonly language: string;

    constructor(language: string) {
        this.language = language;
    }

    get clientApp(): IClientAppResource {
        if (Resources.clientApp[this.language])
            return Resources.clientApp[this.language];

        const translationStack: IClientAppResource[] = [];
        let languageStep = this.language;
        while (languageStep) {
            const translation = ClientAppResource[languageStep];
            if (translation)
                translationStack.push(translation);

            languageStep = languageStep.substring(0, languageStep.lastIndexOf("-"));
        }

        return Resources.clientApp[this.language] = _.merge({}, ClientAppResource[""], ...translationStack) as IClientAppResource;
    }
}

export function getResources(language?: string): Resources {
    return new Resources(language || navigator.language);
}