import React from "react";
import { Route, Switch, RouteComponentProps, Redirect } from "react-router";
import {History} from "history";

import ConferenceRoomRoute from "./components/ConferenceRoomRoute";
import LanguageContext from "./components/LanguageContext";
import Version from "./components/Version";
import Error from "./components/Error";

import { getResources } from "./modules/localization/Resources";

import "./css/app.css";
import "./fonts/icons/foundation-icons/foundation-icons.css";

interface AppProps {
    history: History<History.PoorMansUnknown>
}

export default class App extends React.Component<AppProps>{
    static displayName = App.name;

    state = {
        language: navigator.language,
        setLanguage: (language: string) => {
            const newPath = this.props.history.location.pathname.replace(`/${this.state.language}`, `/${language}`);

            this.setState({language});
            this.props.history.replace(newPath);
        }
    };

    constructor(props: AppProps) {
        super(props);

        this.handleLanguageRoute = this.handleLanguageRoute.bind(this);
    }

    componentWillMount() {
        const languageTagRegex = require("ietf-language-tag-regex");
        const pathParts = this.props.history.location.pathname.split("/");

        if(pathParts[1] && pathParts[1].toUpperCase() !== this.state.language.toUpperCase() && languageTagRegex().test(pathParts[1]))
            this.setState({
                language: pathParts[1]
            });
    }

    handleLanguageRoute(routeProps: RouteComponentProps<{ lang: string, [idx: number]: string }>) {
        return <Redirect to={`/${this.state.language}${routeProps.match.url}`} />
    }

    render() {
        return (
            <LanguageContext.Provider value={this.state}>
                <Switch>
                    <Route exact path={`/${this.state.language}/conference/:roomId`} component={ConferenceRoomRoute}/>
                    <Route path={`/${this.state.language}`} render={() => <Error errorMessage={getResources(this.state.language).clientApp.error_roomNotFound} />}/>
                    <Route path="/*" render={this.handleLanguageRoute}/>
                </Switch>
                <Version className="version-overlay" />
            </LanguageContext.Provider>
        );
    }
}