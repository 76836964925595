import React, { Component } from "react";
import LanguageSwitcher from "./LanguageSwitcher";

export interface IHeaderProps {
    title: string;
}

export class Header extends Component<IHeaderProps, {}> {
    render() {
        return (
            <div className={"header-main"}>
                <div className={"header-title centered"}>
                    {this.props.title}
                </div>
                <LanguageSwitcher className="header-language right"/>
            </div>
        );
    }
}